<template>
  <div>
    <h1>Upload Video Clip</h1>
    <h2>Case {{ caseObj.FileNumber }}</h2>
    <hr>
    <h2>
      <router-link :to="'/cases/edit-action/' + actionObj.ActionID">
        Action {{ actionObj.ActionFileNumber }}
      </router-link>
    </h2>
    <h3>
      <router-link :to="'/cases/' + caseObj.CaseID + '/detail'">
        <span>Case {{ caseObj.FileNumber }}</span>
      </router-link>
      - 
      <router-link
        v-if="caseObj.Branch"
        :to="'/subject/' + caseObj.Branch.Client.Subject.SubjectID "
      >
        <span>{{ caseObj.Branch.Client.Subject.FirstName }}, {{ caseObj.Branch.Client.Subject.LastName }}</span>
      </router-link>
    </h3>
    <div v-if="accessCode && !ChooseFile">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="button"
        :variant="'outline-primary'"
        class="mr-1"
        @click.prevent="chooseFile()"
      >
        Choose File
      </b-button>
    </div>
    <div
      v-if="ChooseFile"
      class="choose-file"
    >
      <div class="title-block">
        <h2 class="title-zone">Claimant Video </h2>
        <b-form-checkbox
          id="encodeWithoutAudioClaimant"
          v-model="EncodeWithoutAudioClaimant"
          name="encodeWithoutAudioClaimant"
          :disabled="IntegrityOnly"
        >
          No Audio
        </b-form-checkbox>
        <img
          v-if="!EncodeWithoutAudioClaimant"
          class="audio-include"
          src="../../assets/images/logo/withSound.png"
          alt="with sound"
        >
        <img
          v-else
          class="audio-include"
          src="../../assets/images/logo/soundless.png"
          alt="soundless"
        >
      </div>
    </div>
    <div v-if="ChooseFile">
      <div
        v-if="IntegrityOnly"
        class="empty-block"
      >
        <h1>Integrity Only has been selected</h1>
      </div>
      <iframe
        v-else
        id="videoUploaderClaimant"
        src="/UploadVideoClip.html"
      />
    </div>
    <div
      v-if="ChooseFile"
      class="choose-file"
    >
      <div class="title-block">
        <h2 class="title-zone">Integrity Video </h2>
        <b-form-checkbox
          id="encodeWithoutAudioIntegrity"
          v-model="EncodeWithoutAudioIntegrity"
          name="encodeWithoutAudioIntegrity"
        >
          No Audio
        </b-form-checkbox>
        <img
          v-if="!EncodeWithoutAudioIntegrity"
          class="audio-include"
          src="../../assets/images/logo/withSound.png"
          alt="with sound"
        >
        <img
          v-else
          class="audio-include"
          src="../../assets/images/logo/soundless.png"
          alt="soundless"
        >
      </div>
    </div>
    <div v-if="ChooseFile">
      <iframe
        id="videoUploaderIntegrity"
        src="/UploadVideoClipIntegrity.html"
      />
    </div>
  </div>
</template>

<script>

import APIService from "@core/utils/APIService";
import {BButton, BFormCheckbox} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActionID: "",
      actionObj: {},
      caseObj: {},
      ChooseFile: false,
      IntegrityOnly: false,

      accessCode: "",
      CaseID: "",
      CaseState: "",
      ActivityID: "",
      UploadedByUser: "",
      UploadedByUserEMail: "",
      ArchiveFolderName: "",
      Title: "",
      EncodeWithoutAudioClaimant: true,
      EncodeWithoutAudioIntegrity: true,
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
  },
  watch: {
    EncodeWithoutAudioClaimant() {
      this.appendSrc();
      document.getElementById('videoUploaderClaimant').contentWindow.location.reload();
    },
    EncodeWithoutAudioIntegrity() {
      this.appendSrc(true);
      document.getElementById('videoUploaderIntegrity').contentWindow.location.reload();
    },
  },
  created() {
    this.ActivityID = this.$route.params.activityID;
    if (this.ActivityID) {
      this.loadActivity();
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            this.actionObj = res.data;
            this.loadCase();
            this.uploaderCode();
          })
    },
    loadActivity() {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.ActionID = res.data.ActionID;
            this.IntegrityOnly = res.data.IntegrityOnly;
            this.loadAction();
          })
    },
    uploaderCode() {
      //if (this.getCurrentScopes.indexOf('"st2.uploadvideo"') !== -1) {
        apiService.get("case/"+ this.actionObj.CaseID + "/uploader-code")
            .then(res => {
              this.UploadedByUser = res.data.UserID;
              this.UploadedByUserEMail = res.data.Email;
              this.accessCode = res.data.UploaderCode;
            });
      // } else {
      //   this.showToast('danger', 'top-center', 4000, "You don't have the upload video permission")
      // }
    },
    loadCase() {
      apiService
          .get("case/" + this.actionObj.CaseID + "/detail")
          .then((res) => {
            this.caseObj = res.data;
            this.CaseID = this.actionObj.CaseID
            this.CaseState = this.caseObj.CaseStateCode

            this.ArchiveFolderName = this.actionObj.CaseFileNumber + "-A" + this.ActivityID
            this.Title = this.actionObj.ActionFileNumber
          })
    },
    chooseFile() {
      this.appendSrc();
      this.appendSrc(true);
      this.ChooseFile = true;
    },
    appendSrc(Integrity = false) {
      const title = Integrity ? (this.Title + "-Integrity") : this.Title;
      const src = 'api/case/get-uploader-script' +
      '?specificheight=500' +
      '&accessCode=' + this.accessCode +
      '&CaseID=' + this.CaseID +
      '&CaseState=' + this.CaseState +
      '&ActionID=' + this.ActionID +
      '&ActivityID=' + this.ActivityID +
      '&UploadedByUser=' + this.UploadedByUser +
      '&UploadedByUserEMail=' + this.UploadedByUserEMail +
      '&AutoArchive=True' +
      '&ArchiveFolderName=' + this.ArchiveFolderName +
      '&Title=' + title +
      '&EncodeWithoutAudio=' + (Integrity ? this.EncodeWithoutAudioIntegrity : this.EncodeWithoutAudioClaimant);
      sessionStorage.setItem((Integrity ?  "videoSrcIntegrity" : "videoSrc"), src);
    },
  },
  beforeRouteLeave (to, from , next) {
    if (this.ChooseFile) {
      const answer = window.confirm('Are you sure you want to leave this page without uploading? \nIncomplete uploads will not save.')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>

<style scoped>
iframe#videoUploaderClaimant,
iframe#videoUploaderIntegrity {
  height: 20rem;
  width: 100%;
  border: none;
}
.audio-include {
  position: absolute;
  top: 0.7rem;
  left: 21rem;
}
.choose-file {
  position: relative;
}
.title-zone {
  width: 14rem;
  font-weight: bold;
}
.title-block {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.empty-block {
  height: 20rem;
  width: 100%;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>